import React, { useState } from 'react'

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from '@hello-pangea/dnd'

import CardComponent from './index'
import { ICardComponentProps, ICardsContainerProps } from './types'

const CardsContainer: React.FC<ICardsContainerProps> = ({
  initialCards,
  iconUrl,
  statusTag,
  idText,
  onCopy,
  onEdit,
  onDelete,
}) => {
  const [cards, setCards] = useState<ICardComponentProps[]>(initialCards)

  const reorder = (
    list: ICardComponentProps[],
    startIndex: number,
    endIndex: number
  ): ICardComponentProps[] => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }
    const newCards = reorder(
      cards,
      result.source.index,
      result.destination.index
    )
    setCards(newCards)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppableCards">
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {cards.map((card: ICardComponentProps, index: number) => (
              <Draggable key={card.id} draggableId={card.id} index={index}>
                {provided => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <CardComponent
                      id={card.id}
                      idText={idText}
                      title={card.title}
                      iconUrl={iconUrl}
                      description={card.description}
                      status={card.status}
                      statusTag={statusTag}
                      onCopy={onCopy}
                      onEdit={onEdit}
                      onDelete={onDelete}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default CardsContainer
