import React, { useState } from 'react'

import { Stack, Text } from '@chakra-ui/react'

import DataFilter, { IFilter } from '@/components/DataFilter'
import DataTable from '@/components/DataTable'
import { IHeader } from '@/components/DataTable/types'
import Pagination from '@/components/Pagination'
import { IPageInfoProps } from '@/components/Pagination/types'

interface IInfoProps {
  title: string
  subtitle: string
}

interface IVersionDateProps {
  date: string
  hour: string
}

const VersionDate = ({ date, hour }: IVersionDateProps) => {
  return (
    <Stack gap={0.5} color="brand.neutral.dark_2">
      <Text fontSize="sm">{date}</Text>
      <Text fontSize="xs">{hour}</Text>
    </Stack>
  )
}
const Info = ({ title, subtitle }: IInfoProps) => {
  return (
    <Stack gap={0.5} color="brand.neutral.dark_2">
      <Text fontSize="sm">{title}</Text>
      <Text fontSize="xs">{subtitle}</Text>
    </Stack>
  )
}

const Versions = () => {
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    itemsCount: 20,
    pageCount: Math.ceil(20 / 10),
    pageSizeOptions: [10, 20, 30, 40, 50],
  })

  const tableHeaders: Array<IHeader> = [
    {
      name: 'Versão',
      key: 'version',
      type: 'element',
      width: '50%',
    },
    {
      name: 'Usuário',
      key: 'user',
      type: 'element',
      width: '25%',
    },
    {
      name: 'Data',
      key: 'date',
      width: '25%',
      type: 'element',
    },
  ]

  const tableData = [
    {
      version: <Info title="V01" subtitle="" />,
      user: <Info title="Nome do usuário" subtitle="Permissão do usuário" />,
      date: <VersionDate date="12/03/2024" hour="00:00:00" />,
    },
    {
      version: <Info title="V02" subtitle="" />,
      user: <Info title="Nome do usuário" subtitle="Permissão do usuário" />,
      date: <VersionDate date="12/03/2024" hour="00:00:00" />,
    },
  ]

  const filters: Array<IFilter> = [
    {
      name: 'date',
      label: 'Data',
      type: 'select',
      options: [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
      ],
    },
  ]

  const handleOnChangePageInfo = ({
    pageIndex,
    itemsPerPage,
  }: IPageInfoProps) => {
    const previousItemsPerPage = pagination.pageSize
    const adjustedPageIndex =
      itemsPerPage !== previousItemsPerPage ? 1 : pageIndex + 1

    setPagination({
      ...pagination,
      page: adjustedPageIndex,
      pageSize: itemsPerPage,
      pageCount: Math.ceil(10 / itemsPerPage),
    })
  }

  return (
    <Stack gap={8}>
      <DataFilter
        testId="data-filter"
        canDownload={false}
        onlySearch
        filters={filters}
        onChangeFilter={() => {}}
      />
      <DataTable headers={tableHeaders} data={tableData} />
      <Pagination
        pageIndex={pagination.page - 1}
        optionsItemsPerPage={pagination.pageSizeOptions}
        itemsPerPage={pagination.pageSize}
        pageCount={pagination.pageCount}
        itemsCount={pagination.itemsCount}
        onChangePageInfo={handleOnChangePageInfo}
      />
    </Stack>
  )
}

export default Versions
