import React, { useState, useEffect } from 'react'
import { FaCalendarAlt, FaSearch, FaDownload, FaFilter } from 'react-icons/fa'
import { FaFilterCircleXmark } from 'react-icons/fa6'

import {
  Grid,
  GridItem,
  InputGroup,
  InputLeftElement,
  Input,
  Select,
  Flex,
  Button,
  InputRightElement,
  useColorModeValue,
  Icon,
} from '@chakra-ui/react'

import { MaskedInput } from '@/components/MaskedInput'

import { IDataFilterProps, TFilter } from './types'

export * from './types'

function DataFilter({
  id,
  testId,
  filters,
  canDownload = true,
  onlySearch = false,
  onClickDownload = () => {},
  onChangeFilter,
}: IDataFilterProps) {
  const [showFilters, setShowFilters] = useState(false)
  const [filtersState, setFiltersState] = useState({} as TFilter)

  const backgroundColor = useColorModeValue('white', 'brand.neutral.dark_1')

  const inputIconColor = useColorModeValue('brand.primary.dark_1', 'white')
  const inputTextColor = useColorModeValue('gray.500', 'white')
  const inputBgColor = useColorModeValue(
    'brand.neutral.light_2',
    'brand.neutral.dark_1'
  )

  const buttonTextColor = useColorModeValue('brand.primary.dark_1', 'white')
  const buttonBorderColor = useColorModeValue('brand.neutral.light_1', 'white')
  const buttonHoverBgColor = 'brand.neutral.light_1'
  const buttonActiveBgColor = 'brand.primary.base'

  const filterButtonTextColor = useColorModeValue('white', 'dark.primary.900')
  const filterButtonBgColor = useColorModeValue(
    'brand.primary.dark_1',
    'brand.primary.dark_1'
  )
  const filterButtonHoverBgColor = useColorModeValue(
    'brand.primary.dark_2',
    'brand.primary.dark_2'
  )
  const filterButtonActiveBgColor = useColorModeValue(
    'brand.primary.base',
    'brand.primary.base'
  )

  const handleChangeShowFilters = () => {
    setShowFilters(!showFilters)
  }

  const handleFilterClick = () => {
    onChangeFilter(filtersState)
  }

  const cleanFilters = () => {
    const cleanFilters = filters.reduce((acc, filter) => {
      return {
        ...acc,
        [filter.name]: '',
      }
    }, {})

    onChangeFilter(cleanFilters)
    setFiltersState(cleanFilters)
  }

  useEffect(() => {
    const customFilters = filters.reduce((acc, filter) => {
      return {
        ...acc,
        [filter.name]: '',
      }
    }, {})

    setFiltersState(prevState => ({
      ...prevState,
      ...customFilters,
    }))
  }, [filters])

  return (
    <Flex
      id={id}
      data-testid={testId}
      direction="column"
      gap="2"
      width="100%"
      backgroundColor={backgroundColor}
    >
      <Flex
        gap="2"
        flexDirection={{
          base: 'column',
          xl: 'row',
        }}
        justifyContent="space-between"
      >
        <Flex flex={2} gap={2}>
          <InputGroup flex={1} bg="white" borderRadius="5px">
            <InputRightElement pointerEvents="none">
              <Icon as={FaSearch} color="brand.primary.dark_1" />
            </InputRightElement>
            <Input
              id={id && `${id}-search`}
              data-testid={testId && `${testId}-search`}
              variant="filled"
              placeholder="Pesquisa"
              color={inputTextColor}
              _placeholder={{
                color: inputTextColor,
                fontStyle: 'italic',
              }}
              backgroundColor={inputBgColor}
              value={filtersState.search}
              onChange={event => {
                setFiltersState({
                  ...filtersState,
                  search: event.target.value,
                })
              }}
            />
          </InputGroup>
          {!onlySearch && (
            <>
              <InputGroup flex={1}>
                <InputLeftElement pointerEvents="none">
                  <Icon as={FaCalendarAlt} color="brand.neutral.dark_1" />
                </InputLeftElement>
                <MaskedInput
                  id={id && `${id}-start-date`}
                  data-testid={testId && `${testId}-start-date`}
                  mask="99/99/9999"
                  variant="filled"
                  placeholder="Data de início"
                  color={inputTextColor}
                  _placeholder={{
                    color: inputTextColor,
                    fontStyle: 'italic',
                  }}
                  backgroundColor={inputBgColor}
                  value={filtersState.startDate}
                  onChange={event => {
                    setFiltersState({
                      ...filtersState,
                      startDate: event.target.value,
                    })
                  }}
                />
              </InputGroup>
              <InputGroup flex={1}>
                <InputLeftElement pointerEvents="none">
                  <Icon as={FaCalendarAlt} color="brand.neutral.dark_1" />
                </InputLeftElement>
                <MaskedInput
                  id={id && `${id}-end-date`}
                  data-testid={testId && `${testId}-end-date`}
                  mask="99/99/9999"
                  variant="filled"
                  placeholder="Data de término"
                  color={inputTextColor}
                  _placeholder={{
                    color: inputTextColor,
                    fontStyle: 'italic',
                  }}
                  backgroundColor={inputBgColor}
                  value={filtersState.endDate}
                  onChange={event => {
                    setFiltersState({
                      ...filtersState,
                      endDate: event.target.value,
                    })
                  }}
                />
              </InputGroup>
            </>
          )}
        </Flex>
        <Flex
          flex={2}
          gap="2"
          sx={{
            '@media (max-width: 400px)': {
              flexDirection: 'column',
            },
          }}
        >
          <Button flex={1} variant="ghost" onClick={handleChangeShowFilters}>
            Mais filtros
          </Button>
          <Button
            flex={1}
            leftIcon={<FaFilterCircleXmark />}
            variant="highlight"
            onClick={cleanFilters}
          >
            Limpar filtro
          </Button>
          <Button
            flex={1}
            id={id && `${id}-filters`}
            data-testid={testId && `${testId}-filters`}
            leftIcon={<FaFilter />}
            color={filterButtonTextColor}
            backgroundColor={filterButtonBgColor}
            _hover={{
              backgroundColor: filterButtonHoverBgColor,
            }}
            _active={{
              backgroundColor: filterButtonActiveBgColor,
            }}
            onClick={handleFilterClick}
          >
            Filtrar
          </Button>
          {canDownload && (
            <Button
              flex={1}
              id={id && `${id}-download`}
              data-testid={testId && `${testId}-download`}
              variant="outline"
              leftIcon={<FaDownload />}
              colorScheme="secondary"
              color={buttonTextColor}
              border="2px solid"
              borderColor={buttonBorderColor}
              _hover={{
                backgroundColor: buttonHoverBgColor,
              }}
              _active={{
                backgroundColor: buttonActiveBgColor,
                borderColor: buttonActiveBgColor,
              }}
              onClick={onClickDownload}
              isDisabled
            >
              Baixar
            </Button>
          )}
        </Flex>
      </Flex>

      {showFilters && (
        <Grid
          rowGap="2"
          columnGap="2"
          templateColumns="repeat(auto-fit, minmax(calc(25% - 0.5rem), 1fr))"
        >
          {filters.map(filter => (
            <GridItem key={`filter-${filter.name}`}>
              {filter.type === 'text' && (
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    {filter.icon || (
                      <Icon as={FaSearch} color={inputIconColor} />
                    )}
                  </InputLeftElement>
                  <Input
                    id={id && `${id}-${filter.name}`}
                    data-testid={testId && `${testId}-${filter.name}`}
                    placeholder={filter.label}
                    color={inputTextColor}
                    _placeholder={{
                      color: inputTextColor,
                    }}
                    backgroundColor={inputBgColor}
                    value={
                      filtersState[filter.name as keyof typeof filtersState]
                    }
                    onChange={event => {
                      setFiltersState({
                        ...filtersState,
                        [filter.name]: event.target.value,
                      })
                    }}
                  />
                </InputGroup>
              )}
              {filter.type === 'select' && (
                <Select
                  id={id && `${id}-${filter.name}`}
                  data-testid={testId && `${testId}-${filter.name}`}
                  placeholder={filter.label}
                  value={filtersState[filter.name as keyof typeof filtersState]}
                  onChange={event => {
                    setFiltersState({
                      ...filtersState,
                      [filter.name]: event.target.value,
                    })
                  }}
                >
                  {filter.options?.map(option => (
                    <option key={`option-${option.value}`} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              )}
            </GridItem>
          ))}
        </Grid>
      )}
    </Flex>
  )
}

export default DataFilter
