import React from 'react'
import { useMemo, useState } from 'react'
import { FaRegEdit } from 'react-icons/fa'
import { FaBook, FaCircleExclamation } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'

import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react'

import DataFilter from '@/components/DataFilter'
import DataTable from '@/components/DataTable'
import Pagination from '@/components/Pagination'
import { IPageInfoProps } from '@/components/Pagination/types'
import Tag from '@/components/Tag'

import { statusFilter, tableHeaders } from './controller'

const EditButton: React.FC<{ id: number }> = ({ id }) => {
  const navigate = useNavigate()
  const handleEdit = () => {
    navigate(`detalhes/${id}`)
  }

  return (
    <IconButton
      aria-label="Editar objeto"
      variant="ghost"
      size="sm"
      icon={<FaRegEdit size="20px" />}
      color="brand.primary.dark_1"
      onClick={handleEdit}
    />
  )
}

interface IVolumesProps {
  // TODO: Modelar dados para a API
  volumes: Array<any>
}

const Phase1Volumes: React.FC<IVolumesProps> = ({ volumes }) => {
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    itemsCount: volumes.length,
    pageCount: Math.ceil(volumes.length / 10),
    pageSizeOptions: [10, 20, 30, 40, 50],
  })

  const handleOnChangePageInfo = ({
    pageIndex,
    itemsPerPage,
  }: IPageInfoProps) => {
    const previousItemsPerPage = pagination.pageSize
    const adjustedPageIndex =
      itemsPerPage !== previousItemsPerPage ? 1 : pageIndex + 1

    setPagination({
      ...pagination,
      page: adjustedPageIndex,
      pageSize: itemsPerPage,
      pageCount: Math.ceil(volumes.length / itemsPerPage),
    })
  }

  const mapTableData = useMemo(() => {
    const start = (pagination.page - 1) * pagination.pageSize
    const end = start + pagination.pageSize
    const paginatedData = volumes.slice(start, end)

    return paginatedData.map(item => {
      const volumeItem = (volumeCode: string) => (
        <VStack align="left">
          <Box w="full">
            <Text fontSize="sm" color="gray.700" textAlign="start">
              {volumeCode}
            </Text>
          </Box>
          <Box>
            <Text fontSize="xs" color="gray.700">
              Editora | Coleção | Volume
            </Text>
          </Box>
        </VStack>
      )
      return {
        ...item,
        volumeCode: volumeItem(item.volumeCode),
        status: <Tag group="statusObjects" value={item.status} />,
        edit: <EditButton id={1} />,
      }
    })
  }, [volumes, pagination])

  if (!volumes.length) {
    return (
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        flexGrow={1}
        gap={6}
        padding="60px"
      >
        <Icon as={FaCircleExclamation} boxSize="128px" color="#475671" />
        <Box w="50%">
          <Text
            color="brand.primary.dark_2"
            textAlign="center"
            fontSize="20px"
            fontWeight="700"
            lineHeight="120%"
          >
            Esse objeto ainda não teve seus volumes dimensionados.
          </Text>
        </Box>
      </Flex>
    )
  }

  return (
    <Flex w="full" direction="column" gap={8}>
      <Box>
        <DataFilter
          testId="data-filter"
          canDownload={false}
          onlySearch
          filters={[statusFilter]}
          onChangeFilter={() => {}}
        />
      </Box>
      <Box>
        <DataTable
          key="table-volumes-modal"
          data={mapTableData}
          headers={tableHeaders}
          selectableRow={false}
        />
        <Pagination
          pageIndex={pagination.page - 1}
          optionsItemsPerPage={pagination.pageSizeOptions}
          itemsPerPage={pagination.pageSize}
          pageCount={pagination.pageCount}
          itemsCount={pagination.itemsCount}
          onChangePageInfo={handleOnChangePageInfo}
        />
      </Box>
      <Flex justify="end">
        <Button leftIcon={<Icon as={FaBook} />}>
          <Text fontSize="sm">Dimensionar volumes</Text>
        </Button>
      </Flex>
    </Flex>
  )
}

export default Phase1Volumes
