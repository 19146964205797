import React from 'react'
import ReactInputMask from 'react-input-mask'
import { Props as InputMaskProps } from 'react-input-mask'

import { Input, InputProps } from '@chakra-ui/react'

import { MaskedInputProps } from './types'

export const MaskedInput = React.forwardRef<HTMLInputElement, MaskedInputProps>(
  ({ mask, ...props }, ref) => {
    const InputMaskComponent =
      ReactInputMask as unknown as React.ComponentType<InputMaskProps>

    return (
      <InputMaskComponent mask={mask}>
        {(inputProps: InputProps) => (
          <Input ref={ref} {...inputProps} {...props} />
        )}
      </InputMaskComponent>
    )
  }
)

MaskedInput.displayName = 'MaskedInput'
